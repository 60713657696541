import React from "react"
import { motion } from "framer-motion"
import services from "../constants/services"
import { Link } from "gatsby"
const servicesVariant = {
  initial: {
    scale: 1,
    transition: {
      duration: 0.05,
    },
  },
  onHover: {
    scale: 1.1,
    transition: {
      duration: 0.05,
    },
  },
}
const Services = () => {
  return (
    <section className="section bg-silver-grey">
      <div className="section-center services-grid">
        {services.map(service => {
          const { id, icon, title, text, classStyle, link } = service
          return (
            <Link to={link} className="services-link" key={service.id}>
              <motion.article
                variants={servicesVariant}
                initial="initial"
                whileHover="onHover"
                key={id}
                className="service"
              >
                {icon}
                <h4>{title}</h4>
                <div className={`underline ${classStyle}`}></div>
                <p>{text}</p>
              </motion.article>
            </Link>
          )
        })}
      </div>
    </section>
  )
}

export default Services
