import React, { useEffect } from "react"
import GridLoader from "react-spinners/GridLoader"

const Loader = () => {
  useEffect(() => {
    document.documentElement.scrollTop = 0
  }, [])

  return (
    <>
      <div className="giga-container">
        <div className={`loader-container`}>
          <GridLoader
            color="#48647f"
            cssOverride={{}}
            loading={true}
            size={30}
            speedMultiplier={1}
          />
        </div>
      </div>
    </>
  )
}

export default Loader
