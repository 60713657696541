import React from "react"
import {
  FaRoad,
  FaTruckMoving,
  FaHardHat,
  FaShoppingCart,
  FaBuilding,
  FaCar,
} from "react-icons/fa"
export default [
  {
    id: 1,
    icon: <FaRoad className="service-icon" />,
    title: "Asphalt",
    link: "/usluge/asfalt",
    classStyle: "underline-sm",
    text: `Asphalt mass is produced on the asphalt plant in Našice. Here you can see complete range of asphalt that we offer for sale`,
  },
  {
    id: 2,
    icon: <FaTruckMoving className="service-icon" />,
    classStyle: "underline-sm",
    title: "Concrete",
    link: "/usluge/beton",
    text: `The concrete factories in Osijek and Štitar produce concrete of the highest quality and durability. Below you can find types of concrete that we offer for sale`,
  },
  {
    id: 3,
    icon: <FaHardHat className="service-icon" />,
    title: "Construction services",
    link: "/usluge/gradjevinskeusluge",
    classStyle: "underline-xl",
    text: `Leave the most demanding construction services to our experts. Reference lists of completed capital projects are the best proof of our seriousness`,
  },
  {
    id: 4,
    icon: <FaShoppingCart className="service-icon" />,
    title: "Assets",
    link: "/usluge/imovina",
    classStyle: "underline-md",
    text: `Here you can view the assets we currently offer for sale. For all additional information, please contact uprava@osijek-koteks.hr`,
  },
  {
    id: 5,
    icon: <FaBuilding className="service-icon" />,
    title: "Real estate",
    link: "/usluge/nekretnine",
    classStyle: "underline-md",
    text: `Here you can view the real estate we currently offer for sale. For all additional information, please contact uprava@osijek-koteks.hr`,
  },
  {
    id: 6,
    icon: <FaCar className="service-icon" />,
    title: "Vehicles",
    link: "/usluge/pokretnine",
    classStyle: "underline-md",
    text: `Here you can view the vehicles that we currently offer for sale. For all additional information, please contact uprava@osijek-koteks.hr`,
  },
]
