import React, { useState, useEffect } from "react"
import { useStaticQuery, graphql } from "gatsby"
import video from "../assets/koteks_video.mp4"
import Image from "gatsby-image"

const query = graphql`
  {
    file(relativePath: { eq: "mainBcg1-min.jpg" }) {
      childImageSharp {
        fluid(sizes: "(max-width: 300px), 1800px") {
          ...GatsbyImageSharpFluid
        }
      }
    }
  }
`

const Background = () => {
  const {
    file: {
      childImageSharp: { fluid },
    },
  } = useStaticQuery(query)
  const [isLargeScreen, setIsLargeScreen] = useState(undefined)
  useEffect(() => {
    function handleResize() {
      setIsLargeScreen(window.innerWidth >= 992)
    }
    handleResize()
    window.addEventListener("resize", handleResize)
    return () => window.removeEventListener("resize", handleResize)
  }, [])
  if (isLargeScreen === undefined) return null
  return (
    <>
      <div className="img-wrapper hide-picture">
        <Image Tag="div" fluid={fluid} loading="eager" className=" bcg  " />
      </div>
      {isLargeScreen && (
        <div className="img-wrapper hide-video">
          <video src={video} autoPlay loop muted className="background-video" />
        </div>
      )}
    </>
  )
}

export default Background
