import React from "react"
import Background from "./Background"
import { useLanguage } from "../contexts/LanguageContext"

const Hero = () => {
  const { selectedLanguage } = useLanguage() // Use the useLanguage hook

  return (
    <header className="hero">
      <Background />
      <div>
        {selectedLanguage === "EN" ? (
          <h3 className="hero-about-us visibility-filter">
            Today, Osijek-Koteks is ready to meet all the challenges investors
            present.
          </h3>
        ) : (
          <h3 className="hero-about-us visibility-filter">
            Osijek-Koteks danas je spreman odgovoriti na sve izazove
            investitora.
          </h3>
        )}
      </div>
    </header>
  )
}

export default Hero
